<template>
  <div class="correction-sheet">
    <GeneralTable ref="GeneralTable"
      :column="column"
      :option="option"
      :dataList="dataList"
      :totalNum="total"
      @search-change="getTableList"
      @del-change="deletCorrectionData"
      @export-data="exportData"
      tableName="CorrectionSheetManagement">
      <!-- 统计插槽 -->
      <template slot="statistics">
        <statistics-box :statisticsList="statisticsList" v-loading="statisticsLoading" />
      </template>

      <template slot="approvalStatusDesc"
        slot-scope="{ item }">
        <div :class="['approval-status', 'approval-status' + item.approvalStatus]">
          {{ item.approvalStatusDesc }}
        </div>
      </template>
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="electronicCorrection(item)"
          v-if="ctrlBtnShow(item.riderStatus) && handleHasPerms(`M25-ep_${$route.meta.policyType}`)">电子批单</el-button>
        <el-button type="text"
          v-if="handleHasPerms(`M25-details_${$route.meta.policyType}`)"
          @click="correctionDetail(item)">批改详情</el-button>
        <el-button type="text"
          @click="downloadDoc(item)"
          v-if="ctrlBtnShow(item.riderStatus) && handleHasPerms(`M25-apply_${$route.meta.policyType}`)"
          :disabled="item.riderStatus != '待审核'">批改申请书</el-button>
        <el-dropdown @command="handleDropdownClick($event, item)"
          v-if="ctrlBtnShow(item.riderStatus)">
          <span class="el-dropdown-link">
            更多
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item
              v-if="ctrlBtnShow(item.riderStatus)"
              command="correct"
            >保单批改</el-dropdown-item>-->
            <el-dropdown-item v-if="
                !ctrlBtnShow(item.riderStatus) &&
                item.orderType != 7 &&
                handleHasPerms(`M25-edit_${$route.meta.policyType}`)
              "
              command="continue">继续批改</el-dropdown-item>
            <el-dropdown-item v-if="
                !ctrlBtnShow(item.riderStatus) && handleHasPerms(`M25-edit_${$route.meta.policyType}`)
              "
              command="cancel">取消批改</el-dropdown-item>
            <el-dropdown-item command="delet"
              v-if="handleHasPerms(`M25-del_${$route.meta.policyType}`)">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span v-else
          style="margin-left: 10px">
          <el-button type="text"
            @click="handleDropdownClick('continue', item)"
            v-if="
              !ctrlBtnShow(item.riderStatus) &&
              item.orderType != 7 &&
              handleHasPerms(`M25-edit_${$route.meta.policyType}`)
            ">继续批改</el-button>
          <el-button type="text"
            @click="handleDropdownClick('cancel', item)"
            v-if="!ctrlBtnShow(item.riderStatus) && handleHasPerms(`M25-edit_${$route.meta.policyType}`)">取消批改</el-button>
          <el-button type="text"
            @click="handleDropdownClick('delet', item)"
            command="delet"
            v-if="handleHasPerms(`M25-del_${$route.meta.policyType}`)">删除</el-button>
        </span>
      </template>
      <template slot="riderId"
        slot-scope="{ item }">
        <el-button type="text">{{ item.riderId }}</el-button>
      </template>
      <template slot="policyNo"
        slot-scope="{ item }">
        <el-button type="text"
          @click="
            $router.push({
              path: `/routerPolicyManagement/${$route.meta.policyRoute}/policyDetails/` + item.orderId,
            })
          ">{{ item.policyNo }}</el-button>
      </template>
      <template slot="frameNo"
        slot-scope="{ item }">
        {{ item.isSingle !== false ? item.frameNo : '多标的类型，详细查看详情' }}
      </template>
      <template slot="riderStatus"
        slot-scope="{ item }">
        <div :class="[
            'status',
            item.riderStatus == '已审核' ? 'status1' : 'status2',
          ]">
          {{ item.riderStatus }}
        </div>
      </template>
    </GeneralTable>
    <UploadCorrection :show.sync="showUpload"
      @submitData="submitUpload"
      :canUpload.sync="canUpload"
      :showBack="uploadShowBack"></UploadCorrection>
  </div>
</template>

<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import StatisticsBox from "@/components/StatisticsBox/index.vue";
import UploadCorrection from "./components/UploadCorrection/index.vue";
import DragMultipleUpload from "@/components/DragMultipleUpload/index.vue";
import { hasPerms, dataPermissions } from "@/utils/auth";
import {
  getCorrectionData,
  uploadCorrectionOrder,
  downloadCorrectionDoc,
  deletCorrection,
  cancelCorrection,
  exportCorrection,
  electronShowBack,
  dictionaryBatch,
  getRiderDataStatistics,
} from "@/api/policy.js";
import { pageListToSelect } from "@/api/basicData.js";
import dayjs from 'dayjs';
import { getPageConfig } from '@/views/CorrectionSheetManagement/pagesConfig.js';
export default {
  name: "correctionSheetManagement1",
  components: {
    GeneralTable,
    StatisticsBox,
    UploadCorrection,
    DragMultipleUpload,
  },
  data() {
    return {
      pageConfig: {},
      showUpload: false,
      canUpload: true,
      uploadShowBack: {},
      total: 0,
      itemOrder: {},
      //列表数据
      dataList: [],
      option: {
        isDel: hasPerms(`M25-del_${ this.$route.meta.policyType }`), //删除 @del-change
        isEdit: hasPerms(`M25-edit_${ this.$route.meta.policyType }`), // 编辑 @edit-change
        isExport: hasPerms(`M25-export_${ this.$route.meta.policyType }`), // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        tableHeight: 'calc(100vh - 122px)', 
        searchList: [
          {
            label: "批单号",
            prop: "riderNumber",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入批单号",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderNumber"),
          },
          {
            label: "保单号",
            prop: "policyNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入保单号",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderNumber"),
          },
          {
            label: "投保人",
            prop: "policyHolder",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入投保人",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "policyHolder"),
          },
          {
            label: "被保人",
            prop: "insured",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入被保人",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insured"),
          },
          {
            label: "险种名称",
            prop: "insuranceTypeName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入险种名称",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insuranceTypeName"),
          },
          {
            label: "起保日期",
            formType: "daterange",
            format: "yyyy.MM.dd",
            clearable: true,
            prop: ["startDateBegin", "startDateEnd"],
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "startDate"),
          },
          {
            label: "保险到期",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["endDateBegin", "endDateEnd"],
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "endDate"),
          },

          {
            label: "保险公司",
            prop: "insuranceCompanyName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入保险公司",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insuranceCompany"),
          },
          {
            label: "分支机构",
            prop: "branchName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入分支机构",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insuranceBranchName"),
          },
          {
            label: "设备类型",
            prop: "deviceTypeName",
            formType: "select",
            filterable: true,
            selectList: [],
            placeholder: "请选择",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "frameNo") && this.$route.meta.policyType != 3,
          },
          {
            label: "车架号",
            prop: "frameNo",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入车架号",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "frameNo"),
          },
          {
            label: "所属部门",
            prop: "salesmanDeptName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入所属部门",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "salesmanDeptName"),
          },
          {
            label: "项目名称",
            prop: "projectNameList",
            formType: "cascader",
            formProps: {
              multiple: true,
              checkStrictly: true,
              emitPath: false,
              value: 'projectName',
              label: 'projectName',
              children: 'subProjectList'
            },
            collapseTags: true,
            clearable: true,
            // filterable: true,
            showAllLevels: false,
            placeholder: "请选择",
            selectList: [],
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "projectName"),
          },
          {
            label: "总保费",
            prop: "totalPremium",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入总保费",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "totalPremium"),
          },
          {
            label: "保单状态",
            prop: "customerType",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "未起保" },
              { value: "2", text: "保障中" },
              { value: "3", text: "已过期" },
            ],
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "policyState"),
          },
          {
            label: "业务员",
            prop: "salesmanName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入业务员",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "salesmanName"),
          },
          {
            label: "批改状态",
            prop: "riderStatus",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: "1", text: "待审核" },
              { value: "2", text: "已审核" },
              { value: "3", text: "待修改" },
            ],
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderStatus"),
          },
          {
            label: "录单员",
            prop: "createUserName",
            formType: "input",
            maxLength: 36,
            clearable: true,
            placeholder: "请输入录单员",
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "recorder"),
          },
          {
            label: "批改时间",
            formType: "daterange",
            format: "yyyy.MM.dd",
            clearable: true,
            prop: ["effectiveTimeBegin", "effectiveTimeEnd"],
            // propValue: [dayjs().format('YYYY-MM-DD 00:00:00'),dayjs().format('YYYY-MM-DD 23:59:59')],  //2023-8-18 默认创建日期的话想改回不然每次直接搜索都需再按一遍取消创建日期
            isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderCreateTime"),
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "批单号",
          prop: "riderNumber",
          width: 280,
          isShow: true,
          // isSlot: true,,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderNumber"),
        },
        {
          label: "投保日期",
          prop: "insuranceOfDate",
          width: 280,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insuranceOfDate"),
        },
        {
          label: "保单号",
          prop: "policyNo",
          width: 280,
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "policyNo"),
        },
        {
          label: "投保人",
          prop: "policyHolder",
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "policyHolder"),
        },
        {
          label: "被保险人",
          prop: "insured",
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insured"),
        },
        {
          label: "险种名称",
          prop: "insuranceTypeName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insuranceTypeName"),
        },
        {
          label: "险种类型",
          prop: "insuredCategoryName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insuredCategory"),
        },
        {
          label: "保险公司",
          prop: "insuranceCompanyName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "insuranceCompany"),
        },
        {
          label: "分支机构",
          prop: "insuranceBranchName",
          width: 265,
          isShow: true,
          isPermissions: dataPermissions(`M26_${ this.$route.meta.policyType }-View`, "insuranceBranchName"),
        },
        {
          label: "设备类型",
          prop: "deviceTypeName",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "frameNo"),
        },
        {
          label: "车架号",
          prop: "frameNo",
          width: 200,
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "frameNo"),
        },
        {
          label: "项目名称",
          prop: "projectName",
          align: "center",
          width: 200,
          isShow: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "projectName"),
        },
        {
          label: "所属部门",
          prop: "salesmanDeptName",
          align: "center",
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "salesmanDeptName"),
        },
        {
          label: "起保日期",
          prop: "startDate",
          width: 100,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "startDate"),
        },
        {
          label: "保险到期",
          prop: "endDate",
          width: 100,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "endDate"),
        },
        {
          label: "总保费(元)",
          prop: "totalPremium",
          width: 100,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "totalPremium"),
        },
        {
          label: "批改保费",
          prop: "gapVal",
          width: 100,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "totalPremium"),
        },
        {
          label: "批改状态",
          prop: "riderStatus",
          isShow: true,
          isSlot: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderStatus"),
        },
        {
          label: "业务员",
          prop: "salesmanName",
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "salesmanName"),
        },
        {
          label: "代理人",
          prop: "agentName",
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "agentName"),
        },
        //   {
        //     label: "审核状态",
        //     prop: "approvalStatusDesc",
        //     isShow: true,
        //     isSlot: true,
        //     isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderStatus"),
        //   },
        {
          label: "录单员",
          prop: "createUserName",
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "createUserName"),
        },
        {
          label: "批改时间",
          prop: "riderCreateTime",
          width: 200,
          isShow: true,
          isPermissions: dataPermissions(`M25_${ this.$route.meta.policyType }-View`, "riderCreateTime"),
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 330,
          fixed: "right",
          isSlot: true,
        },
      ],
      statisticsList: [
        {
          name: '批单总数',
          value: 0
        },
        {
          name: '签单总保费',
          value: 0
        },
        {
          name: '总批改保费',
          value: 0
        },
        {
          name: '合计总保费',
          value: 0,
        },
      ],
      statisticsLoading: false
    };
  },
  created() {
    this.pageConfig = getPageConfig(this.$route.meta.policyType);
    this.column = this.column.map(item => {
      return {
        ...item,
        isPermissions: item.isPermissions && !this.pageConfig.table.disShowItem.includes(item.prop)
      };
    });
    dictionaryBatch({
      codes: "insuranceCompany,vehicleType,CXvehicleType",
    }).then((res) => {
      // let ind2 = this.option.searchList.findIndex(
      //   (val) => val.label === "项目名称"
      // );
      // this.option.searchList[ind2].selectList = res.data.projectName.map(
      //   (val) => {
      //     return {
      //       text: val.dictValue,
      //       value: val.dictKey,
      //     };
      //   }
      // );
      if (res && res.data) {
        let ind = this.option.searchList.findIndex(
          (val) => val.label === "保险公司"
        );
        this.option.searchList[ind].selectList = res.data.insuranceCompany.map(
          (val) => {
            return {
              text: val.dictValue,
              value: val.dictKey,
            };
          }
        );
        this.option.searchList[ind].selectList.unshift({
          text: "全部",
          value: "",
        });
        if (this.$route.meta.policyType != 3) {
          let indv = this.option.searchList.findIndex(
            (val) => val.label === "设备类型"
          );
          this.option.searchList[indv].selectList = this.$route.meta.policyType == 2 ? res.data.CXvehicleType.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictValue,
              };
            }
          ) : res.data.vehicleType.map(
            (val) => {
              return {
                text: val.dictValue,
                value: val.dictValue,
              };
            }
          );
          this.option.searchList[indv].selectList.unshift({
            text: "全部",
            value: "",
          });
        }
      }
    });
    
      pageListToSelect({
        projectName: '',
        bladeUserId: this.$store.state.userInfo.MJUserId,
      }).then(res=>{
          let ind2 = this.option.searchList.findIndex(
            (val) => val.label === "项目名称"
          );
          this.option.searchList[ind2].selectList = res.data;
      })
  },
  methods: {
    exportData(data, obj) {
      // if (!data.length) {
      //   this.$message({
      //     message: "请勾选！",
      //     type: "warning",
      //   });
      //   return;
      // }
      // let riderIds = data.map((item) => {
      //   return item.riderId;
      // });
      if (obj.effectiveTimeBegin) {
        obj.effectiveTimeBegin = dayjs(obj.effectiveTimeBegin).format('YYYY-MM-DD') + " 00:00:00";
        obj.effectiveTimeEnd = dayjs(obj.effectiveTimeEnd).format('YYYY-MM-DD') + " 23:59:59";
      }
      exportCorrection(obj).then((res) => {
        this.$message({
          message: "导出成功！",
          type: "success",
        });
      });
    },
    deletCorrectionData(data) {
      if (!data.length) {
        this.$message({
          message: "请勾选！",
          type: "warning",
        });
        return;
      }
      this.deletListData(data);
    },
    addOrEdit() { },
    //导入批单
    importData() { },
    async getTableList(data, current, size, searchType) {
      if (data.effectiveTimeBegin) {
        data.effectiveTimeBegin = dayjs(data.effectiveTimeBegin).format('YYYY-MM-DD') + " 00:00:00";
        data.effectiveTimeEnd = dayjs(data.effectiveTimeEnd).format('YYYY-MM-DD') + " 23:59:59";
      }
      data.frameNo = data.frameNo || data.frameNoLike || '';
      this.statisticsLoading = true;
      getRiderDataStatistics({
        ...data,
        category: this.$route.meta.policyType,
        current,
        size,
      }).then(res=>{
        this.statisticsLoading = false;
        this.statisticsList[0].value = res.data.total || 0
        this.statisticsList[1].value = res.data.signedTotalPremium || 0
        this.statisticsList[2].value = res.data.riderTotalPremium || 0
        this.statisticsList[3].value = res.data.totalPremium || 0
      }).catch(err=>{
        this.statisticsLoading = false;
      })
      let result = await getCorrectionData({
        ...data,
        category: this.$route.meta.policyType
        //   current,
        //   size,
      }, current, size);
      let { records, total } = result.data;
      this.dataList = records;
      this.total = total;
    },
    //控制操作选项显示
    ctrlBtnShow(state) {
      if (state == "待修改") return false;
      return true;
    },
    //电子批单
    async electronicCorrection(data) {
      this.itemOrder = data;
      if (data.riderStatus == "已审核") {
        this.canUpload = false;
        let res = await electronShowBack({ riderId: data.riderId });
        this.uploadShowBack = res.data;
      } else {
        this.uploadShowBack = null;
      }
      this.showUpload = true;
    },
    //上传电子批单
    submitUpload(data) {
      uploadCorrectionOrder({
        ...data,
        riderId: this.itemOrder.riderId,
        orderId: this.itemOrder.orderId,
      }).then(
        (res) => {
          this.$refs.GeneralTable.getList();
          this.$message({
            message: "上传成功！",
            type: "success",
          });
          this.showUpload = false;
        },
        (err) => {
          this.$message({
            message: "上传失败！",
            type: "error",
          });
        }
      );
    },
    //批改详情
    correctionDetail(data) {
      this.$router.push({
        name: "correctionDetails",
        params: {
          riderId: data.riderId,
        },
      });
    },
    //下载批单
    downloadDoc(data) {
      downloadCorrectionDoc({
        orderId: data.orderId,
        riderId: data.riderId,
      }).then(
        (res) => {
          this.$message({
            message: "下载成功！",
            type: "success",
          });
        },
        (err) => {
          this.$message({
            message: "下载失败！",
            type: "error",
          });
        }
      );
    },
    //删除数据
    deletListData(data) {
      let orderIdList = data.map((item) => {
        return {
          orderId: item.orderId,
          approvalType: item.approvalType,
          riderId: item.riderId,
        };
      });
      this.$confirm(`删除后数据不能恢复是否确认要删除？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deletCorrection(orderIdList).then(
          (res) => {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.$refs.GeneralTable.getList();
          },
          (err) => {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        );
      });
    },
    handleDropdownClick(value, data) {
      switch (value) {
        case "continue":
        case "correct":
          {
            this.$router.push({
              name: `correctionEdit${ this.$route.meta.policyType }`,
              params: {
                orderId: data.orderId,
                approvalType: data.approvalType,
                riderId: data.riderId,
              },
            });
          }
          break;
        case "cancel":
          {
            this.$confirm(`确认取消保单批改？`, "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              cancelCorrection([
                {
                  orderId: data.orderId,
                  approvalType: data.approvalType,
                  riderId: data.riderId,
                },
              ]).then(
                (res) => {
                  this.$message({
                    message: "取消成功！",
                    type: "success",
                  });
                  this.$refs.GeneralTable.getList();
                },
                (err) => {
                  this.$message({
                    message: "取消失败！",
                    type: "error",
                  });
                }
              );
            });
          }
          break;
        case "delet":
          {
            this.deletListData([data]);
          }
          break;
        default:
          break;
      }
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.approval-status {
  position: relative;
  padding-left: 18px;
  font-size: 14px;
}
.approval-status::after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.approval-status3 {
  color: #00bc0d;
}
.approval-status3::after {
  background-color: #00bc0d;
}
.approval-status2 {
  color: #0080ff;
}
.approval-status2::after {
  background-color: #0080ff;
}
.approval-status4 {
  color: #cccccc;
}
.approval-status4::after {
  background-color: #cccccc;
}
.approval-status1 {
  color: #c94242;
}
.approval-status1::after {
  background-color: #c94242;
}
.approval-status5 {
  color: #00bc0d;
}
.approval-status5::after {
  background-color: #00bc0d;
}
.correction-sheet {
  .status {
    position: relative;
    padding-left: 18px;
    font-size: 14px;
    display: inline-block;
  }
  .status::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .status1 {
    color: #00bc0d;
  }
  .status1::after {
    background-color: #00bc0d;
  }
  .status2 {
    color: #0080ff;
  }
  .status2::after {
    background-color: #0080ff;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  margin-left: 12px;
  font-size: 14px;
}
</style>
