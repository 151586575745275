<template>
  <el-dialog
    destroy-on-close
    :visible.sync="dialogVisible"
    @close="close"
    :close-on-click-modal="false"
  >
    <template slot="title">{{ title }}</template>
    <template #footer>
      <div class="bottom-btn">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button v-if="canUpload" type="primary" @click="submitData"
          >确定</el-button
        >
      </div>
    </template>
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item prop="riderNumber" required label="批单号：">
        <el-input
          :disabled="!canUpload"
          v-model="form.riderNumber"
          placeholder="请输入批单号"
          @input="input_text($event, form, 'riderNumber')"
          maxlength="32"
        ></el-input>
      </el-form-item>
      <el-form-item prop="effectiveTime" required label="生效时间：">
        <el-date-picker
          :disabled="!canUpload"
          v-model="form.effectiveTime"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="年/月/日"
        ></el-date-picker>
      </el-form-item>
      <div v-if="canUpload">
        <el-upload
          class="el-upload"
          drag
          action="#"
          :limit="1"
          :on-exceed="handleExceed"
          :on-change="uploadChange"
          :http-request="() => {}"
          :fileList="fileList"
          :on-remove="removeFile"
        >
          <div class="upload-top">
            <el-button class="button">上传文件</el-button>
            <div class="common tips">
              <span>
                支持格式：.jpg .jpeg .png .pdf
                ，单个文件不能超过2MB，最多上传1个文件
              </span>
            </div>
          </div>

          <div class="upload">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              拖拽到此或点击上方
              <em>上传文件</em>
            </div>
          </div>
        </el-upload>
      </div>
      <div v-else>
        <el-button @click="viewFile" type="text">查看电子保单</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import { OSSDirectPass } from "@/api/oss.js";
export default {
  name: "UploadCorrection",
  props: {
    title: {
      type: String,
      default: "电子批单",
    },
    show: {
      type: Boolean,
      default: false,
    },
    //支持的文件后缀名
    fileTypes: {
      type: Array,
      default: () => ["jpg", "png", "jpeg", "pdf"],
    },
    //单个文件最大大小(M)
    fileSzie: {
      type: Number,
      default: 2,
    },
    canUpload: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        riderNumber: "",
        effectiveTime: "",
        attachmentUrl: "",
      },
      rules: {
        riderNumber: [
          {
            required: true,
            message: "请输入批单号",
            trigger: ["change", "blur"],
          },
        ],
        effectiveTime: [
          {
            required: true,
            message: "请输入生效时间",
            trigger: ["change", "blur"],
          },
        ],
      },
      fileList: [],
    };
  },
  watch: {
    dialogVisible(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      this.dialogVisible = n;
      if (this.showBack) {
        console.log(this.showBack);
        this.form = this.showBack;
      }
    },
  },
  methods: {
    async uploadChange(file, fileList) {
      let suffix = this.getSuffix(file.name);
      if (this.fileTypes.indexOf(suffix) == -1) {
        this.$message({
          message: `不支持该文件格式${suffix}`,
          type: "warning",
        });
        this.fileList = [];
        return;
      }
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > this.fileSzie) {
        this.$message({
          message: `上传的文件大于${this.fileSzie}MB`,
          type: "warning",
        });
        this.fileList = [];
        return;
      }
      let fileUrl = await OSSDirectPass(file);
      if (fileUrl) {
        this.form.attachmentUrl = fileUrl;
      }
    },
    getSuffix(fileName) {
      let arr = fileName.split(".");
      if (arr.length) {
        return arr[arr.length - 1];
      }
      return "";
    },
    handleExceed() {
      this.$message({
        message: "上传文件数量过多！",
        type: "warning",
      });
    },
    submitData() {
      if (!this.canUpload) {
        this.dialogVisible = false;
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.attachmentUrl) {
            this.$emit("submitData", this.form);
          } else {
            this.$message({
              message: "请上传电子批单！",
              type: "warning",
            });
          }
        }
      });
    },
    close() {
      this.$emit("update:canUpload", true);
      this.form = { riderNumber: "", effectiveTime: "", attachmentUrl: "" };
    },
    removeFile(file, fileList) {
      this.form.attachmentUrl = "";
    },
    // 格式化限制数字和字母
    input_text(e, tiem, key, max, decimal = 2) {
      // 清除"数字"和字母以外的字符
      tiem[key] = e.replace(/[^\w]/g, "");
    },
    viewFile() {
      window.open(this.form.attachmentUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input {
  width: 100%;
}
::v-deep .el-dialog__header {
  background-color: #4b7bca;
  border-radius: 10px 10px 0 0;
  font-weight: bold;
  color: #ffffff;
  font-size: 18px;
}
::v-deep .el-dialog {
  border-radius: 10px;
  width: 640px;
}
::v-deep .el-upload-dragger {
  border: none;
  height: auto;
}
.el-icon-upload {
  color: #4278c9ff;
  font-size: 48px;
}
.upload {
  background: #f3f8ff;
  border-radius: 8px;
  opacity: 1;
  border: 1px dashed #d1e3fe;
  height: 173px;
  width: 100%;
}
.el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 576px;
}
.common {
  margin: 0 0 12px 12px;
}
.button {
  border: 1px solid #0080ff;
  font-size: 14px;
  font-weight: 400;
  color: #0080ff;
  display: block;
  margin-top: 2px;
}
.el-upload__text {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}
.tips {
  font-size: 12px;
  font-weight: 300;
  color: #333333;
  text-align: left;
  margin: 8px 0 12px 0;
}
.upload-top {
  display: column;
  align-items: flex-start;
}
</style>
