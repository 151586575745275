var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"correction-sheet"},[_c('GeneralTable',{ref:"GeneralTable",attrs:{"column":_vm.column,"option":_vm.option,"dataList":_vm.dataList,"totalNum":_vm.total,"tableName":"CorrectionSheetManagement"},on:{"search-change":_vm.getTableList,"del-change":_vm.deletCorrectionData,"export-data":_vm.exportData},scopedSlots:_vm._u([{key:"approvalStatusDesc",fn:function({ item }){return [_c('div',{class:['approval-status', 'approval-status' + item.approvalStatus]},[_vm._v(" "+_vm._s(item.approvalStatusDesc)+" ")])]}},{key:"operate",fn:function({ item }){return [(_vm.ctrlBtnShow(item.riderStatus) && _vm.handleHasPerms(`M25-ep_${_vm.$route.meta.policyType}`))?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.electronicCorrection(item)}}},[_vm._v("电子批单")]):_vm._e(),(_vm.handleHasPerms(`M25-details_${_vm.$route.meta.policyType}`))?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.correctionDetail(item)}}},[_vm._v("批改详情")]):_vm._e(),(_vm.ctrlBtnShow(item.riderStatus) && _vm.handleHasPerms(`M25-apply_${_vm.$route.meta.policyType}`))?_c('el-button',{attrs:{"type":"text","disabled":item.riderStatus != '待审核'},on:{"click":function($event){return _vm.downloadDoc(item)}}},[_vm._v("批改申请书")]):_vm._e(),(_vm.ctrlBtnShow(item.riderStatus))?_c('el-dropdown',{on:{"command":function($event){return _vm.handleDropdownClick($event, item)}}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" 更多 "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
              !_vm.ctrlBtnShow(item.riderStatus) &&
              item.orderType != 7 &&
              _vm.handleHasPerms(`M25-edit_${_vm.$route.meta.policyType}`)
            )?_c('el-dropdown-item',{attrs:{"command":"continue"}},[_vm._v("继续批改")]):_vm._e(),(
              !_vm.ctrlBtnShow(item.riderStatus) && _vm.handleHasPerms(`M25-edit_${_vm.$route.meta.policyType}`)
            )?_c('el-dropdown-item',{attrs:{"command":"cancel"}},[_vm._v("取消批改")]):_vm._e(),(_vm.handleHasPerms(`M25-del_${_vm.$route.meta.policyType}`))?_c('el-dropdown-item',{attrs:{"command":"delet"}},[_vm._v("删除")]):_vm._e()],1)],1):_c('span',{staticStyle:{"margin-left":"10px"}},[(
            !_vm.ctrlBtnShow(item.riderStatus) &&
            item.orderType != 7 &&
            _vm.handleHasPerms(`M25-edit_${_vm.$route.meta.policyType}`)
          )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDropdownClick('continue', item)}}},[_vm._v("继续批改")]):_vm._e(),(!_vm.ctrlBtnShow(item.riderStatus) && _vm.handleHasPerms(`M25-edit_${_vm.$route.meta.policyType}`))?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDropdownClick('cancel', item)}}},[_vm._v("取消批改")]):_vm._e(),(_vm.handleHasPerms(`M25-del_${_vm.$route.meta.policyType}`))?_c('el-button',{attrs:{"type":"text","command":"delet"},on:{"click":function($event){return _vm.handleDropdownClick('delet', item)}}},[_vm._v("删除")]):_vm._e()],1)]}},{key:"riderId",fn:function({ item }){return [_c('el-button',{attrs:{"type":"text"}},[_vm._v(_vm._s(item.riderId))])]}},{key:"policyNo",fn:function({ item }){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.$router.push({
            path: `/routerPolicyManagement/${_vm.$route.meta.policyRoute}/policyDetails/` + item.orderId,
          })}}},[_vm._v(_vm._s(item.policyNo))])]}},{key:"frameNo",fn:function({ item }){return [_vm._v(" "+_vm._s(item.isSingle !== false ? item.frameNo : '多标的类型，详细查看详情')+" ")]}},{key:"riderStatus",fn:function({ item }){return [_c('div',{class:[
          'status',
          item.riderStatus == '已审核' ? 'status1' : 'status2',
        ]},[_vm._v(" "+_vm._s(item.riderStatus)+" ")])]}}])},[_c('template',{slot:"statistics"},[_c('statistics-box',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.statisticsLoading),expression:"statisticsLoading"}],attrs:{"statisticsList":_vm.statisticsList}})],1)],2),_c('UploadCorrection',{attrs:{"show":_vm.showUpload,"canUpload":_vm.canUpload,"showBack":_vm.uploadShowBack},on:{"update:show":function($event){_vm.showUpload=$event},"submitData":_vm.submitUpload,"update:canUpload":function($event){_vm.canUpload=$event},"update:can-upload":function($event){_vm.canUpload=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }